const binding = { modules: {}, dataActions: {} };

    (binding.modules['content-block'] = {
        c: () => require('@msdyn365-commerce-modules/content-block/dist/lib/modules/content-block/content-block'),
        $type: 'contentModule',
        da: [],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'content-block',
        p: 'content-block',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/content-block/dist/lib/modules/content-block'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|themes|loacker|views|content-block'] = {
                c: () => require('partner/themes/loacker/views/content-block.view.tsx'),
                cn: '__local__-__local__-content-block'
            };
viewDictionary['@msdyn365-commerce-modules|content-block|modules|content-block|content-block'] = {
                c: () => require('@msdyn365-commerce-modules/content-block/dist/lib/modules/content-block/content-block.view.js'),
                cn: '@msdyn365-commerce-modules-content-block-content-block'
            };
viewDictionary['@msdyn365-commerce-theme|adventureworks-theme-kit|modules|adventureworks|views|content-block'] = {
                c: () => require('@msdyn365-commerce-theme/adventureworks-theme-kit/dist/lib/modules/adventureworks/views/content-block.view.js'),
                cn: '@msdyn365-commerce-theme-adventureworks-theme-kit-content-block'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };